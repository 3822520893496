import React from "react";
import { FaCaretDown as ArrowDownIcon } from "react-icons/fa6";
import { baseRoute } from "../../constants/routes";

export default function DropDown() {
  const [open, setOpen] = React.useState(false);

  return (
    <li
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className="relative dropdown bg-transparent dropdown-hover"
    >
      <div
        tabIndex={0}
        role="button"
        className="flex hover:bg-gradient-dark-blue hover:text-white items-center"
      >
        Features
        <ArrowDownIcon />
      </div>
      <ul
        tabIndex={0}
        style={{
          opacity: open ? 1 : 0,
          visibility: open ? "visible" : "hidden",
        }}
        className="absolute dropdown-content z-50 menu p-2 shadow bg-gray-100 rounded-box min-w-max text-[16px] font-normal"
      >
        <li
          className="hover:bg-gradient-dark-blue hover:text-white rounded-lg"
          onClick={() => setOpen(false)}
        >
          <a href={`${baseRoute}/features/integrated-accounts`}>
            Integrated Accounts
          </a>
        </li>
        <li
          className="hover:bg-gradient-dark-blue hover:text-white rounded-lg"
          onClick={() => setOpen(false)}
        >
          <a href={`${baseRoute}/features/mpc-wallet`}>MPC Wallet</a>
        </li>
        <li
          className="hover:bg-gradient-dark-blue hover:text-white rounded-lg"
          onClick={() => setOpen(false)}
        >
          <a href={`${baseRoute}/features/safe-multisig`}>Safe Multisig</a>
        </li>
        <li
          className="hover:bg-gradient-dark-blue hover:text-white rounded-lg"
          onClick={() => setOpen(false)}
        >
          <a href={`${baseRoute}/features/tokenizing`}>
            Tokenizing Trade Documents
          </a>
        </li>
      </ul>
    </li>
  );
}
