import "./App.css";
import FileReaderComponent from "./components/FileReaderComponent";
import Navbar from "./components/shared/Navbar";
import hero from "./components/shared/assets/hero.png";
import logo from "./components/shared/assets/logo.png";
import folderImg from "./components/shared/assets/folder.png";
import arrowRightImg from "./components/shared/assets/arrow-right.png";
import verifiedImg from "./components/shared/assets/verified.png";
import graphImg from "./components/shared/assets/graph.png";
import { ReactComponent as RaysSvg } from "./components/shared/assets/rays.svg";
import Footer from "./components/shared/Footer";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { chainList } from "./constants/chainlist";
import { baseRoute } from "./constants/routes";

function App() {
  // 1. Get projectId at https://cloud.walletconnect.com
  const projectId = process.env.REACT_APP_WC_PROJECT_ID!;

  // 3. Create modal
  const metadata = {
    name: "Blockpeer Verifier",
    description: "Blockpeer finance document verifier",
    url: "https://verify.blockpeer.finance", // origin must match your domain & subdomain
    icons: [
      "https://res.cloudinary.com/dligdeoxo/image/upload/v1709214271/ecj4bkieyxrm2r9eujyn.png",
    ],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({
      metadata,
      defaultChainId: 1,
      enableEIP6963: true,
      enableInjected: true,
      enableCoinbase: true,
      rpcUrl: "...", // used for the Coinbase SDK
    }),
    chains: [...chainList],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
  });

  return (
    <div className="max-w-screen-2xl mx-auto">
      <Navbar />
      <div className="h-6"></div>
      <main className="md:p-20 mt-4 text-blue-900 flex flex-col gap-10 md:gap-28">
        <section className="mx-4 flex flex-col lg:flex-row items-center justify-between">
          <div className="flex flex-col gap-10">
            <div className="flex items-center">
              <RaysSvg
                className="-translate-y-full -translate-x-full absolute"
                height={50}
                width={50}
              />
              <h1 className="font-serif py-2 bg-gradient-dark-blue text-transparent bg-clip-text text-3xl lg:text-5xl font-bold">
                Simplifying Electronic Document Verification with BlockPeer
              </h1>
            </div>
            <p className="text-lg lg:text-2xl text-center lg:text-left lg:leading-loose">
              Welcome to the BlockPeer Verifier Page, where simplicity meets
              security in managing your electronic documents.
            </p>
          </div>
          <img
            src={hero}
            width={443}
            height={349}
            alt="Hero"
            className="blur-[2.5px]"
          />
        </section>
        <p className="text-lg lg:text-2xl text-center lg:text-left lg:leading-loose">
          Utilizing the innovative Tradetrust framework, our platform offers a
          straightforward process for verifying the authenticity and integrity
          of verifiable documents like electronic Invoices (eInvoices) and
          transferable documents such as electronic Bills of Lading (eBLs).
        </p>
        <div className="flex flex-col items-center gap-10">
          <h2 className="bg-gradient-dark-blue text-center bg-clip-text text-transparent font-serif text-3xl font-bold">
            How It Works
          </h2>
          <p className="text-lg lg:text-2xl text-center lg:text-left lg:leading-loose">
            Whether issued directly from BlockPeer’s integrated accounting
            platform or any third-party service adhering to the Tradetrust
            framework, our verifier page ensures that your electronic documents
            are secure, authentic, and unaltered. Simply upload your .tt
            document file, and our verifier will check:
          </p>
        </div>
        <div className="self-center -mb-20">
          <img
            src={logo}
            alt=""
            width={188}
            height={43}
            className="self-center"
          />
        </div>
        <section className="flex flex-col lg:flex-row items-center gap-4 mx-auto">
          <div className="flex flex-col px-11 gap-6 items-center">
            <img
              src={folderImg}
              height={200}
              width={200}
              alt="Documents Folder"
            />
            <p className="text-xl">Upload Document</p>
          </div>
          <img
            src={arrowRightImg}
            height={200}
            width={200}
            alt="Arrow Right"
            className="rotate-90 lg:rotate-0"
          />
          <div className="flex flex-col px-11 gap-6 items-center">
            <img src={verifiedImg} height={200} width={200} alt="Verified" />
            <p className="text-xl ">Verify Docments</p>
          </div>
          <img
            src={arrowRightImg}
            height={200}
            width={200}
            alt="Arrow Right"
            className="rotate-90 lg:rotate-0"
          />
          <div className="flex flex-col px-11 gap-6 items-center">
            <img
              src={graphImg}
              height={200}
              width={200}
              alt="Result Infograph"
            />
            <p className="text-xl ">Results</p>
          </div>
        </section>
        <ul className="list-disc flex flex-col gap-10 text-lg lg:text-2xl lg:leading-loose">
          <li>
            <strong>Issuance Verification:</strong> Confirms if the electronic
            document has been officially issued and recorded on the Blockchain.
          </li>
          <li>
            <strong>Issuer Identification:</strong> Verifies that the document
            issuer has been identified using DNS-DID, ensuring the domain name
            is correctly resolving.
          </li>
          <li>
            <strong>Tamper Check:</strong> Determines whether the document has
            remained unaltered from the point of issuance.
          </li>
        </ul>
        <section className="flex flex-col gap-4 justify-start w-full">
          <FileReaderComponent />
        </section>
        <h3 className="-mb-20 bg-gradient-dark-blue text-center self-center bg-clip-text text-transparent font-serif text-3xl font-bold">
          Transferable Document Management
        </h3>
        <p className="text-lg lg:text-2xl text-center lg:text-left lg:leading-loose">
          For documents like eBLs that require transfer, nomination, or
          surrender, our verifier page facilitates these actions seamlessly,
          ensuring a smooth transition of document ownership within the secure
          framework.
        </p>
        <h4 className="-mb-20 bg-gradient-dark-blue text-center self-center bg-clip-text text-transparent font-serif text-3xl font-bold">
          Beyond Verification
        </h4>
        <p className="text-lg lg:text-2xl text-center lg:text-left lg:leading-loose">
          While the Verifier Page is dedicated to document verification,
          issuance of eInvoices and eBLs requires our comprehensive Integrated
          Accounting Platform. Subscribe to BlockPeer and experience the ease of
          issuing MLETR-compliant electronic trade documents directly from your
          accounting dashboard. Not only does this streamline your document
          management, but it also opens doors to quicker access to trade finance
          through our network of BlockPeer partners.
        </p>
        <h5 className="-mb-20 bg-gradient-dark-blue text-center self-center bg-clip-text text-transparent font-serif text-3xl font-bold">
          Join BlockPeer Today
        </h5>
        <p className="text-lg lg:text-2xl text-center lg:text-left lg:leading-loose">
          Embrace the future of finance management with BlockPeer. Verify,
          manage, and issue your trade documents effortlessly, all while
          securing your financial operations with the latest in blockchain
          technology. Subscribe to BlockPeer’s Integrated Accounting Platform,
          and transform the way you do business in the digital age.
        </p>
        <a
          href={`${baseRoute}/schedule-a-demo`}
          className="py-2 px-3 md:py-5 md:px-7 rounded-xl font-semibold bg-green-500 text-white hover:bg-green-300 hover:text-green-950 shadow-md shadow-green-950 self-center"
        >
          Schedule a Demo
        </a>
      </main>
      <Footer />
    </div>
  );
}
export default App;

/*  trade trust supported chains
    CHAIN_ID["local"] = "1337";
    CHAIN_ID["mainnet"] = "1";
    CHAIN_ID["matic"] = "137";
    CHAIN_ID["maticmum"] = "80001";
    CHAIN_ID["sepolia"] = "11155111";
    CHAIN_ID["xdc"] = "50";
    CHAIN_ID["xdcapothem"] = "51";
*/
