import axios from "axios";
import { useState } from "react";
import Logo from "./assets/logo.png";
import {
  FaSquareXTwitter as TwitterLogo,
  FaLinkedinIn as LinkedInIcon,
} from "react-icons/fa6";

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [email, setEmail] = useState("");
  const [isInValidEmail, setIsInValidEmail] = useState(false);

  const handleSubmit = async () => {
    try {
      console.log("sending...");
      if (!email || email.trim() === "") {
        setIsInValidEmail(true);
        return;
      }
      setLoading(true);
      setIsInValidEmail(false);
      const response = await axios.post("/api/newsletter", { email: email });
      const data = response.data;
      if (data.inlineMessage) {
        setSaved(true);
        setEmail("");
      }
    } catch (error) {
      console.error("error: ", error);
      setEmail("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <footer className="mt-10">
      <section className="font-montserrat flex flex-col items-center">
        <h4 className="text-neutral-600 text-lg text-center tracking-wider">Subscribe to Get Our E-mail</h4>
        <h5 className="bg-gradient-dark-blue bg-clip-text text-transparent font-extrabold text-5xl tracking-wide">Newsletter</h5>
        <form className="flex px-4 flex-col max-w-screen-md self-center w-full my-10 gap-5">
          <div className="flex relative items-center flex-1 rounded-full bg-neutral-200 border-[2px] border-white ">
            <input
              name="email"
              placeholder="Insert Your E-mail Here"
              className="py-3 pl-6 flex items-center bg-transparent w-full outline-none placeholder-neutral-500 focus:bg-transparent"
              id="newsletter-input"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />

            <label htmlFor="newsletter-input" className="hidden">
              E-Mail
            </label>
            {/* for desktop */}
            <button
              type="button"
              disabled={loading}
              onClick={() => handleSubmit()}
              className="rounded-full items-center gap-4 hidden md:flex border-l-[2px] py-3 px-14  border-white bg-gradient-dark-blue text-white text-xl font-semibold"
            >
              Subscribe
              {loading && <span className="loading loading-spinner loading-md"></span>}
            </button>
          </div>
          {/* for mobile */}
          <button
            type="button"
            disabled={loading}
            onClick={() => handleSubmit()}
            className="rounded-full flex items-center gap-4 border-l-[2px] self-center md:hidden py-3 px-14 border-white bg-gradient-dark-blue text-white text-xl font-semibold"
          >
            Subscribe
            {loading && <span className="loading loading-spinner loading-md"></span>}
          </button>
          {isInValidEmail && <small className="text-xs text-red-500 ml-5 -mt-4">Please enter a valid email!</small>}
          {saved && <small className="text-xs text-green-500 ml-5 -mt-4">Thank you for subscribing to our newsletter.</small>}
        </form>
      </section>
      <section className="flex flex-col w-full border-b-2 border-neutral-600 pb-4">
        <div className="flex justify-between gap-2 flex-wrap *:min-w-max mx-2 sm:mx-10 lg:mx-36 text-neutral-500">
          <ul className="flex flex-col gap-2">
            <h6 className="text-neutral-800 font-semibold text-xl">Platform</h6>
            <a href="https://blockpeer.finance/">Home</a>
            <a href="https://blockpeer.finance/features/integrated-accounts">Features</a>
            <a href="https://blockpeer.finance/schedule-a-demo">Demo</a>
          </ul>
          <ul className="flex flex-col gap-2">
            <h6 className="text-neutral-800 font-semibold text-xl">Company</h6>
            <a href="https://blockpeer.finance/about-us">About Us</a>
            <a href="https://blockpeer.finance/carrers">Carrers</a>
            <a href="https://blockpeer.finance/contact-us">Contact Us</a>
          </ul>
          <ul className="flex flex-col gap-2">
            <h6 className="text-neutral-800 font-semibold text-xl">Resources</h6>
            <a href="https://blockpeer.finance/blogs">Blogs</a>
            <a href="https://www.blockpeer.finance/demos">Security</a>
          </ul>
          <ul className="flex flex-col gap-2">
            <h6 className="text-neutral-800 font-semibold text-xl">Legal</h6>
            <a href="https://blockpeer.finance/policies/Cookie_policy.html" target="_blank">
              Cookie Policy
            </a>
            <a href="https://blockpeer.finance/policies/Privacy_Policy.html" target="_blank">
              Privacy Policy
            </a>
            <a href="https://blockpeer.finance/policies/Terms_Of_Service.html" target="_blank">
              Terms of Service
            </a>
            <a href="https://blockpeer.finance/policies/Acceptable_Use_Policy.html" target="_blank">
              Acceptable Use Policy
            </a>
          </ul>
        </div>
        <div className="flex justify-between mt-1 items-center mx-2 sm:mx-10 md:mx-20">
          <div className="flex flex-col gap-2">
            <img src={Logo} height={50} width={250} alt="Block Peer Logo" className="h-12 w-60" />
            <span className="text-neutral-600 text-sm">Digital Asset Operations & Accounting For Businesses</span>
          </div>
          <div className="flex gap-2 mr-8">
            <a rel="noreferrer" href="https://x.com/blockpeerfin" target="_blank">
              <TwitterLogo className="w-6 h-6 sm:w-9 sm:h-9" />
            </a>
            <a
              rel="noreferrer"
              href="https://www.linkedin.com/company/blockpeer-finance"
              target="_blank"
              className="rounded-full bg-blue-600 flex items-center justify-center w-6 h-6 sm:w-9 sm:h-9"
            >
              <LinkedInIcon className="fill-white w-5 h-5 sm:w-6 sm:h-6" />
            </a>
          </div>
        </div>
      </section>
      <section className="flex flex-col self-stretch text-sm text-center text-neutral-600">
        ©2024 BlockPeer Technologies, Inc. <br /> All Rights Reserved.
      </section>
    </footer>
  );
};

export default Footer;
