import { useState, useEffect, useCallback } from "react";
import { providers, Signer } from "ethers";
import { TitleEscrowFactory__factory, TitleEscrow__factory } from "@tradetrust-tt/token-registry/contracts";
import { TitleEscrow } from "@tradetrust-tt/token-registry/contracts";
import { TradeTrustToken } from "@tradetrust-tt/token-registry/contracts";
import { BurnAddress } from "../../configs"

interface useTitleEscrowContractProps {
  titleEscrow?: TitleEscrow;
  documentOwner?: string;
  updateTitleEscrow: () => Promise<void>;
}

export const useTitleEscrowContract = (
  provider: providers.Provider | Signer | undefined,
  tokenRegistry?: TradeTrustToken,
  tokenId?: string
): useTitleEscrowContractProps => {
  const [titleEscrow, setTitleEscrow] = useState<TitleEscrow>();
  const [documentOwner, setDocumentOwner] = useState<string>();

  const updateTitleEscrow = useCallback(async () => {
    if (!tokenRegistry || !tokenId || !provider) return;
    const titleEscrowOwner = await tokenRegistry.ownerOf(tokenId);
    setDocumentOwner(titleEscrowOwner);
   
    const instance = await connectToTitleEscrow({
      provider,
      tokenRegistry,
      tokenId,
    });
    setTitleEscrow(instance);
    
  }, [provider, tokenId, tokenRegistry]);

  useEffect(() => {
    updateTitleEscrow();
    return () => {
      setTitleEscrow(undefined);
      setDocumentOwner(undefined);
    };
  }, [updateTitleEscrow, tokenId, provider]);

  return { titleEscrow, updateTitleEscrow, documentOwner };
};

interface ConnectToTitleEscrowArgs {
  provider: providers.Provider | Signer;
  tokenRegistry: TradeTrustToken;
  tokenId: string;
}
export const connectToTitleEscrow = async ({
  provider,
  tokenRegistry,
  tokenId,
}: ConnectToTitleEscrowArgs): Promise<TitleEscrow> => {
  try {
    
    const tokenRegistryAddress = tokenRegistry.address;
    const titleEscrowOwner = await tokenRegistry.ownerOf(tokenId);
    //console.log("titleEscrowOwner", titleEscrowOwner);
    const inactiveEscrow = [BurnAddress, tokenRegistryAddress].includes(titleEscrowOwner);
    let titleEscrowAddress = titleEscrowOwner;
    if (inactiveEscrow) {
      titleEscrowAddress = await retrieveTitleEscrowAddressOnFactory(tokenRegistry, tokenId, provider);
    }
    return TitleEscrow__factory.connect(titleEscrowAddress, provider);
  } catch (error) {
    throw error;
  }
};

export const retrieveTitleEscrowAddressOnFactory = async (
  tokenRegistry: TradeTrustToken,
  tokenId: string,
  signer: providers.Provider | Signer
): Promise<string> => {
  try {
    const titleEscrowFactoryAddress = await tokenRegistry.titleEscrowFactory();
    const tokenRegistryAddress = tokenRegistry.address;
    const titleEscrowFactory = TitleEscrowFactory__factory.connect(titleEscrowFactoryAddress, signer);
    const titleEscrowAddress = await titleEscrowFactory.getAddress(tokenRegistryAddress, tokenId);
    return titleEscrowAddress;
  } catch (error) {
    throw error;
  }
};




