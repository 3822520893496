type INetworkType = "sapphire_devnet" | "sapphire_mainnet";
export const NETWORK_TYPE: INetworkType = process.env.REACT_APP_NET as INetworkType || "sapphire_devnet";

export const InitialAddress = "0x0000000000000000000000000000000000000000";
export const BurnAddress = "0x000000000000000000000000000000000000dEaD";

export enum ChainId {
  // Localhost
  Local = 1337,

  // Ethereum Mainnet
  Ethereum = 1,

  // Ethereum Testnet
  Sepolia = 11155111,

  // Polygon
  Polygon = 137,
  PolygonMumbai = 80001,

  // XDC Network
  XDC = 50,
  APOTHEM = 51,

  // Stability
  StabilityTestnet = 20180427,
}