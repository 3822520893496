import {
  ShieldExclamationIcon,
} from "@heroicons/react/24/solid";
import { ReactComponent as VerifySVG } from "./shared/assets/verify.svg";

type Props = {
  isValidDocStatus: boolean;
  isValidIntegrity: boolean;
  isValidIssuer: boolean;
  issuer: string;
};
const ValidDocument = ({
  issuer,
  isValidDocStatus,
  isValidIntegrity,
  isValidIssuer,
}: Props) => {

  return (
    <div className="w-full flex flex-col bg-neutral-50 shadow shadow-neutral-500 rounded-lg p-4 lg:px-10 lg:py-20">
      <div className="flex items-center justify-between flex-col lg:flex-row gap-4 w-full mb-10 lg:mb-20">
        <p className="font-serif text-3xl md:text-5xl font-bold text-center lg:text-left">
          Issued by{" "}
          <span className="font-bold capitalize text-[#1435E3]">{issuer}</span>
        </p>
      </div>

      <div className="w-full font-serif font-bold text-black flex flex-col md:flex-row items-start md:items-center justify-evenly gap-2 md:gap-8 mt-3">
        <div className="flex gap-2 items-center text-sm">
          <div>
            {isValidDocStatus ? (
              <VerifySVG className="text-green-500" />
            ) : (
              <ShieldExclamationIcon className="w-6 text-red-500" />
            )}
          </div>
          <p>Document has been issued</p>
        </div>
        <div className="flex gap-2 items-center text-sm">
          <div>
            {isValidIssuer ? (
              <VerifySVG className="text-green-500" />
            ) : (
              <ShieldExclamationIcon className="w-6 text-red-500" />
            )}
          </div>

          <p>Document issuer has been identified</p>
        </div>
        <div className="flex gap-2 items-center text-sm">
          <div>
            {isValidIntegrity ? (
              <VerifySVG className="text-green-500" />
            ) : (
              <ShieldExclamationIcon className="w-6 text-red-500" />
            )}
          </div>

          <p>Document has not been tampered with</p>
        </div>
      </div>
      <div className="hidden items-center gap-4 mt-5">
        <p className="border p-1 px-4 border-orange-500 bg-gray-100 rounded-lg text-orange-500">Transferable</p>
        <p className="border p-1 px-4 border-orange-500 bg-gray-100 rounded-lg text-orange-500">Negotiable</p>
      </div>
    </div>
  );
};

export default ValidDocument;

