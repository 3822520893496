type Route = {
  id: number;
  name: string;
  href: string;
}

export const baseRoute = "https://blockpeer.finance"

export const routes: Route[] = [
  { id: 1, name: "Home", href: baseRoute },
  { id: 2, name: "Features", href: baseRoute },
  { id: 3, name: "About", href: `${baseRoute}/about-us` },
  { id: 4, name: "Partners", href: `${baseRoute}/partner` },
  { id: 5, name: "Integrations", href: `${baseRoute}/integration` },
  { id: 6, name: "Pricing", href: `${baseRoute}/pricing` },
  { id: 7, name: "Blogs", href: `${baseRoute}/blogs` },
  { id: 8, name: "Verify Docs", href: "https://verifier.blockpeer.finance" },
];