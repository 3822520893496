import React from "react";
import { baseRoute, routes } from "../../constants/routes";

export default function MobileNavbarMenu() {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="dropdown">
      <label
        tabIndex={0}
        role="button"
        /*  onClick={() => setOpen((o) => !o)} */
        className="btn btn-ghost lg:hidden swap swap-rotate"
      >
        <input
          type="checkbox"
          readOnly
          checked={open}
          onClick={() => setOpen((o) => !o)}
        />

        {/* hamburger icon */}
        <svg
          className="swap-off fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512 512"
        >
          <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
        </svg>

        {/* close icon */}
        <svg
          className="swap-on fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512 512"
        >
          <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
        </svg>
      </label>
      <ul
        tabIndex={0}
        style={{
          opacity: open ? 1 : 0,
          visibility: open ? "visible" : "hidden",
        }}
        className="menu menu-sm space-y-5 text-[16px] dropdown-content mt-3 z-30 p-2 shadow bg-gray-100 rounded-box w-52 text-blue-900 font-regular leading-[20px]"
      >
        {mobileRoutes.map((menu) => (
          <React.Fragment key={menu.id}>
            {menu.name === "Features" ? (
              <li>
                <details open>
                  <summary>{menu.name}</summary>
                  <ul className="space-y-4">
                    <li onClick={() => setOpen(false)}>
                      <a href={`${baseRoute}/features/integrated-accounts`}>
                        Integrated Accounts
                      </a>
                    </li>
                    <li onClick={() => setOpen(false)}>
                      <a href={`${baseRoute}/features/mpc-wallet`}>
                        MPC Wallet
                      </a>
                    </li>
                    <li onClick={() => setOpen(false)}>
                      <a href={`${baseRoute}/features/safe-multisig`}>
                        Safe Multisig
                      </a>
                    </li>
                    <li onClick={() => setOpen(false)}>
                      <a href={`${baseRoute}/features/tokenizing`}>
                        Tokenizing Trade Documents
                      </a>
                    </li>
                  </ul>
                </details>
              </li>
            ) : (
              <li onClick={() => setOpen(false)} key={menu.id}>
                <a href={menu.href}>{menu.name}</a>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
}

const mobileRoutes = [
  ...routes,
  {
    id: 9,
    name: "Book A meeting",
    href: "https://meetings-eu1.hubspot.com/venkatesh-rm",
  },
];
