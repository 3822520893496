import React, { Fragment } from "react";
import DropDown from "./DropDown";
import MobileNavbarMenu from "./MobileNavbarMenu";
import logo from "./assets/logo.png";
import { baseRoute, routes } from "../../constants/routes";

const Navbar = () => {
  const [showNav, setShowNav] = React.useState(true);
  const [lastScrollY, setLastScrollY] = React.useState(0);

  React.useEffect(() => {
    function toggleNav() {
      if (lastScrollY > window.scrollY || window.scrollY < 100) {
        setShowNav(true);
      } else {
        setShowNav(false);
      }
      setLastScrollY(window.scrollY);
    }
    window.addEventListener("scroll", toggleNav);

    return () => window.removeEventListener("scroll", toggleNav);
  }, [lastScrollY]);

  return (
    <div
      style={{
        transform: !showNav ? "translateY(-100%)" : "unset",
      }}
      className="navbar navbg sticky text-blue-900 font-medium top-0 z-50 border-none transition-all duration-300 ease-in-out"
    >
      <div className="navbar-start">
        <MobileNavbarMenu />
        <a
          href={baseRoute}
          className="text-xl max-w-[200px] transition-all duration-200 ease-in-out hover:shadow hover:shadow-blue-500 hover:bg-blue-100 md:p-2 rounded-lg"
        >
          <img
            width={250}
            height={60}
            src={logo}
            alt="Blockpeer Logo"
            className="w-full"
          />
        </a>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1 text-[16px] text-[#032D80] font-regular leading-[20px]">
          {routes.map((menu) => (
            <Fragment key={menu.id}>
              {menu.name === "Features" ? (
                <DropDown />
              ) : (
                <li
                  key={menu.id}
                  className="hover:bg-gradient-dark-blue rounded-lg hover:text-white"
                >
                  <a href={menu.href}>{menu.name}</a>
                </li>
              )}
            </Fragment>
          ))}
        </ul>
      </div>
      <div className="navbar-end space-x-4 hidden lg:flex">
        <a
          href="https://meetings-eu1.hubspot.com/venkatesh-rm"
          target="_blank"
          className="btn bg-transparent px-10 btn-outline text-blue-500 hover:bg-blue-50 hover:text-blue-900"
          rel="noreferrer"
        >
          Book a meeting
        </a>
        <a
          href={`${baseRoute}/schedule-a-demo`}
          className="btn hover:bg-green-800 bg-green-500 text-white"
        >
          Schedule a Demo
        </a>
      </div>
    </div>
  );
};

export default Navbar;
