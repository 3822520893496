import { ShieldExclamationIcon } from "@heroicons/react/24/solid";
type Props = {
  msg: string;
};
const InvalidDocument = ({ msg }: Props) => {
  return (
    <div className="flex items-center justify-center flex-col gap-4">
      <div className="flex items-center gap-1">
        <ShieldExclamationIcon className="w-6 text-error" />
        <p className="text-xl">This document is not valid</p>
      </div>
      <p className="text-red-500 font-bold text-xl">{msg}</p>
      <p>This document was issued by an invalid issuer.</p>
      <p className="text-red-500 font-bold">Try another document</p>
    </div>
  );
};

export default InvalidDocument;
